import React from 'react'

//Components
import Layout from '../../components/layout/layout'
import LoginForm from '../../components/forms/login-form'
import Title from '../../components/global/title'
import SEO from '../../components/seo'

const LoginPage = () => {
  return (
    <Layout>
      <SEO title="Log in" />
      <Title
        main="Log in"
        subTitle="Ingresa a tu cuenta"
        options={{ center: true }}
      />
      <LoginForm />
    </Layout>
  )
}

export default LoginPage
