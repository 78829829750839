import React, { Component } from 'react'
import { navigate } from 'gatsby'

// Components
import Input from './input'

// Services
import { handleLogin } from '../../services/auth'

// Validation
import validate from 'validate.js'
import loginValidation from './validators/login'

export default class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    errors: {},
  }

  /**
   * Handle input change
   * @param {Object} event
   */
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  /**
   * Validates input values onBlur event
   */
  validateInput = async event => {
    const target = event.target
    const value = target.value
    const name = target.name
    const validationError = validate.single(value, loginValidation[name])
    if (validationError && validationError.length) {
      this.setState({
        errors: { ...this.state.errors, [name]: [validationError[0]] },
      })
    } else {
      this.setState({ errors: { ...this.state.errors, [name]: [] } })
    }
  }

  /**
   * Submit forms
   * @param {Object} event
   */
  handleSubmit = async event => {
    event.preventDefault()
    try {
      const { email, password } = this.state
      const validationErrors = validate({ email, password }, loginValidation)
      if (validationErrors)
        return this.setState({
          errors: { ...this.state.errors, ...validationErrors },
        })
      const { success, user } = await handleLogin({ email, password })
      if (success) {
        return navigate('/users/home', { state: { user } })
      }
    } catch (e) {
      console.error(e)
    }
  }

  forgotPassword = async () => {
    navigate('/auth/password-reset')
  }

  render() {
    return (
      <div className="container mx-auto mt-5 text-slide-in">
        <div className="grid grid-cols-12">
          <div className=" col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 text-center">
            <form onSubmit={this.handleSubmit}>
              <div className="form-input">
                <Input
                  classes="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="text"
                  name="email"
                  placeholder="Correo electrónico"
                  value={this.state.email}
                  errors={this.state.errors['email']}
                  onChange={this.handleInputChange}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="form-input">
                <Input
                  className="w-full bg-black p-4 focus:bg-white focus:text-black"
                  type="password"
                  name="password"
                  placeholder="Contraseña"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  errors={this.state.errors['password']}
                  onBlur={this.validateInput}
                />
              </div>
              <div className="grid grid-cols-12">
                <div className="col-span-12 mb-2">
                  <div
                    className="cursor-pointer hover:underline"
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    onClick={this.forgotPassword}
                  >
                    ¿Olvidaste tu contraseña?
                  </div>
                </div>
                <div className="col-span-12">
                  <button
                    className="default-button py-3 w-full px-8 mb-48 mt-6"
                    type="submit"
                  >
                    Acceder
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )
  }
}
